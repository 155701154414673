import React, { useRef, useState, useEffect } from "react"
import { Helmet } from 'react-helmet'
import "../global.css"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import HeadMetadata from "../components/HeadMetadata"
import HeroDesarrollos from '../desarrollos/HeroDesarrollos'
import Layout from '../layout/Layout'
import logoCarboneras from '../images/logoCarbonerasBig.png'
import CallToAction from '../layout/CallToActionC'


export default () => {

  const img = useStaticQuery(graphql`
    {
      fondoHero: file(relativePath: { eq: "fondoCarboneras.jpg" }) {
        sharp: childImageSharp { fluid(maxWidth: 1800, quality:90) { ...GatsbyImageSharpFluid } } }
      img2: file(relativePath: { eq: "img1.png" }) { 
        sharp: childImageSharp { fluid(maxWidth: 1800,quality:90) { ...GatsbyImageSharpFluid } } }
      img3: file(relativePath: { eq: "img2.png" }) {
        sharp: childImageSharp { fluid(maxWidth:1800, quality:90) { ...GatsbyImageSharpFluid } } }
      img4: file(relativePath: { eq: "img3.jpg" }) {
        sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
      img5: file(relativePath: { eq: "img4.png" }) {
        sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
      img6: file(relativePath: { eq: "img5.png" }) {
        sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
      img7: file(relativePath: { eq: "img6.png" }) {
        sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
      img8: file(relativePath: { eq: "img7.jpg" }) {
          sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
      img9: file(relativePath: { eq: "img8.png" }) {
        sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
      img10: file(relativePath: { eq: "img9.png" }) {
        sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
      img11: file(relativePath: { eq: "img10.png" }) {
        sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
      img12: file(relativePath: { eq: "img11.png" }) {
        sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
      img13: file(relativePath: { eq: "img12.jpg" }) {
        sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
      img14: file(relativePath: { eq: "img13.jpg" }) {
        sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
      img15: file(relativePath: { eq: "img14.jpg" }) {
          sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
      img16: file(relativePath: { eq: "img15.jpg" }) {
          sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
      img17: file(relativePath: { eq: "carbonerasslider.jpg" }) {
        sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
      

          img2Res: file(relativePath: { eq: "img1Res.png" }) { 
            sharp: childImageSharp { fluid(maxWidth: 1800,quality:90) { ...GatsbyImageSharpFluid } } }
          img3Res: file(relativePath: { eq: "img2Res.png" }) {
            sharp: childImageSharp { fluid(maxWidth:1800, quality:90) { ...GatsbyImageSharpFluid } } }
          img4Res: file(relativePath: { eq: "img3Res.jpg" }) {
            sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
          img5Res: file(relativePath: { eq: "img4Res.png" }) {
            sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
          img6Res: file(relativePath: { eq: "img5Res.png" }) {
            sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
          img7Res: file(relativePath: { eq: "img6Res.png" }) {
            sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
          img8Res: file(relativePath: { eq: "img7Res.jpg" }) {
              sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
          img9Res: file(relativePath: { eq: "img8Res.png" }) {
            sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
          img10Res: file(relativePath: { eq: "img9Res.png" }) {
            sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
          img11Res: file(relativePath: { eq: "img10Res.png" }) {
            sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
          img12Res: file(relativePath: { eq: "img11Res.png" }) {
            sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
          img13Res: file(relativePath: { eq: "img12Res.jpg" }) {
            sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
          img14Res: file(relativePath: { eq: "img13Res.jpg" }) {
            sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
          img15Res: file(relativePath: { eq: "img14Res.jpg" }) {
              sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
          img16Res: file(relativePath: { eq: "img15Res.jpg" }) {
              sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
          img17Res: file(relativePath: { eq: "carbonerasslider.jpg" }) {
            sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
    }
  `)

  return (
    <Layout className="overflow-hidden">
      <HeadMetadata title="Desarrollo Carboneras - Grupo Libera" />


      <HeroDesarrollos fondo={img.fondoHero.sharp.fluid} logo={logoCarboneras} />
      
      <Helmet>
      <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/6452874.js"></script>
      </Helmet>  
      <section>
        <Img fluid={img.img2.sharp.fluid}  className="hidden lg:block" />  
        <Img fluid={img.img2Res.sharp.fluid}  className="lg:hidden" />  
      </section>    
              
     <section>
          <Img fluid={img.img3.sharp.fluid}  className="hidden lg:block" />         
          <Img fluid={img.img3Res.sharp.fluid}  className="lg:hidden" />  
      </section> 
      <a href="https://www.google.com/maps/place/Carboneras+Sisal/@21.1216207,-89.9365633,17z/data=!3m1!4b1!4m5!3m4!1s0x85ff55f1d02056bd:0xd5239c24d98e6ac8!8m2!3d21.1216207!4d-89.9343746?shorturl=1" target="blank">    
      <section>
          <Img fluid={img.img4.sharp.fluid}  className="hidden lg:block" />
          <Img fluid={img.img4Res.sharp.fluid}  className="lg:hidden" />
      </section>
      </a>      
      <section>
          <Img fluid={img.img5.sharp.fluid}  className="hidden lg:block" />
          <Img fluid={img.img5Res.sharp.fluid} className="lg:hidden" />
      </section>
      
      <section>
          <Img fluid={img.img6.sharp.fluid}  className="hidden lg:block" />
          <Img fluid={img.img6Res.sharp.fluid} className="lg:hidden" />
      </section>
      <section>
          <Img fluid={img.img7.sharp.fluid}  className="hidden lg:block" />
          <Img fluid={img.img7Res.sharp.fluid} className="lg:hidden" />
      </section>
      <section>
          <Img fluid={img.img8.sharp.fluid}  className="hidden lg:block" />
          <Img fluid={img.img8Res.sharp.fluid} className="lg:hidden" />
      </section>
      <section>
          <Img fluid={img.img9.sharp.fluid}  className="hidden lg:block" />
          <Img fluid={img.img9Res.sharp.fluid} className="lg:hidden" />
      </section>
      <section>
          <Img fluid={img.img10.sharp.fluid}  className="hidden lg:block" />
          <Img fluid={img.img10Res.sharp.fluid} className="lg:hidden" />
      </section>
      <section>
          <Img fluid={img.img11.sharp.fluid}  className="hidden lg:block" />
          <Img fluid={img.img11Res.sharp.fluid} className="lg:hidden" />
      </section>
      <section>
          <Img fluid={img.img12.sharp.fluid}  className="hidden lg:block" />
          <Img fluid={img.img12Res.sharp.fluid} className="lg:hidden" />
      </section>
      
      <section>
          <Img fluid={img.img13.sharp.fluid}  className="hidden lg:block" />
          <Img fluid={img.img13Res.sharp.fluid} className="lg:hidden" />
      </section>
      <a href="/desarrolloCarbonerasReserva">
      <section>
          <Img fluid={img.img17.sharp.fluid}  className="hidden lg:block" />
          <Img fluid={img.img17Res.sharp.fluid} className="lg:hidden" />
      </section>
      </a>
      
      
      
              
      <CallToAction
        phone="tel:9993462300"
        whatsapp="https://wa.me/+529993462300"
        email="mailto:info@grupolibera.mx"
      />

    </Layout >
  )
}

